<template>
  <v-card>
    <v-card-title>{{ $t('adjustBookableHours') }}</v-card-title>
    <v-card-subtitle>{{ $t('changeWhatHoursAreBookable') }}</v-card-subtitle>
    <v-card-actions>
      <v-btn
        class="primary"
        block
        large
        disabled
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
	name: 'AdjustBookableHours'
}
</script>
